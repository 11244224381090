/*
Version 0.115

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	01-03-2018
	- [ADDED] Added document.ready to onready.
	26-02-2018
	- [ADDED] Open en Close methods are extended with new function to prevent body scrolling when menu is open
2017
	30-11-2017
	- [CHANGE] .js-menu-button is now changed to .js-mobile-menu-button
	15-10-2017
	- [METHOD] Add and remove .active class to the js-menu-button.
	30-08-2017
	- [BORN] This class is born. Hooray!

!Usage
	specific_mobilemenu.onready();

	
!Wishlist

	none
	
*/
  
var specific_overlaymenu = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	isMenuOpen = 0;
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	this.onready = function() {
		
		/*
		Menu: Overlay
		*/
		$('.js-menu-fixed').on('click',function() {
			specific_overlaymenu.toggle();
		});
				
	};
	
	this.toggle = function(closeDirectly) {
			
		if(closeDirectly == 1) {

			specific_overlaymenu.close();

		} else {

			if(isMenuOpen == 0) {

				specific_overlaymenu.open();

			} else {

				specific_overlaymenu.close();

			}

		}
		
	};

	this.open = function () {

		/*
		Set menu left pixels
		*/
		var _left = 0;
		if($(window).width() < 400) {
			_left = -170;
		} else {
			_left = -100;
		}
		
		$('.js-menu-fixed-overlay').stop().animate({
			'top':-200,
			'left':_left
		},700);

		$('.line.line-2').hide();

		$('.line.line-1').stop().animate({
			'top':8,
			'transform':'rotate(-45deg)'
		},200);
		$('.line.line-3').stop().animate({
			'top':8,
			'transform':'rotate(45deg)'
		},200);

		isMenuOpen = 1;

	};

	this.close = function () {
		
		$('.js-menu-fixed-overlay').stop().animate({
			'top':-700,
			'left':-700
		},700);

		$('.line.line-2').show();

		$('.line.line-1').stop().animate({
			'top':0,
			'transform':'rotate(0)'
		},200);
		$('.line.line-3').stop().animate({
			'top':16,
			'transform':'rotate(0)'
		},200);

		isMenuOpen = 0;

	};
		
}).apply(specific_overlaymenu); 