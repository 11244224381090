/*
Version 0.110

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	08-03-2019
	- [CHANGE] Added variable check to set height and with when not given in on windowResize();
	11-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_window = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	// none
	
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
		/**
		Window: resize
		**/
		this.windowResize = function(scrolled,h,w) {
			
			/*
			Set: Variables
			*/
			if(typeof h === 'undefined') {
				h = $(window).height();
			}
			if(typeof w === 'undefined') {
				w = $(window).width();
			}

			// document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
			
		};
		
		/**
		Window: scroll
		**/
		this.windowScroll = function(scrolled,h,w) {

			/*
			Scrolled navigation
			*/
			//if(w > 500) {
				if(scrolled >= 100) {
					
					if(_fixed_shown == 0) {
					
						$('header.desktop').stop().animate({
							'top':-200
						},400);

						$('.js-logo').stop().animate({
							'top':-200
						},400);

						$('.js-menu-fixed').stop().animate({
							'top':-200
						},400);

						_fixed_shown = 1;

					}


				} else {

					if(_fixed_shown == 1) {

						$('header.desktop').stop().animate({
							'top':0
						},400);

						$('.js-logo').stop().animate({
							'top':40
						},400);

						$('.js-menu-fixed').stop().animate({
							'top':0
						},400);

						_fixed_shown = 0;

					}

				}
			//}

			/*
			Projects
			*/			
		    $('.js-fade-inonscroll').each(function () {

		    	/*
				Marge
		    	*/
		    	var _marge;
		    	if(w < 400) {
		    		_marge = (h + 100);
		    	} else {
		    		_marge = (h + 250);
		    	}

		        if (scrolled > $(this).offset().top - _marge) {
		        	
		            $(this).animate({
		            	'opacity':1,
		            	'margin-top': 0
		            }, Math.floor(Math.random() * 1000) + 500,'swing');
		        	
		        }

		    });

		    /*
		    Menu button
		    */
		    $('.js-menu-fixed').stop().animate({
            	'transform': 'translateY(40px)'
            },500);

            /*
	    	Trigger elements is in view?
	    	*/
	    	for (i = 0; i < 10; i++) {

				var _element 		= $('.js-scrollinview-' + i.toString());

				if(_element.length > 0 ) {

					var _position		= _element.get(0).getBoundingClientRect();

					specific_interactions_window.isInView(
						_element,
						_position
					);

				}

			}

		};
		
		
		/**
		Window: orientation
		**/
		this.windowOrientationChange = function() {
			
		};


		this.isInView = function (
			_element,
			_position
		){

			/*
			Check if already triggered
			*/
			if(!_element.hasClass('triggered')) {

				/*
				Is Inview?
				*/
				if(_position.top < window.innerHeight && _position.bottom >= 0) {

					var _element_name	= _element.data('viewelement');

					// if(_element_name == 'footer') {

					// 	$('.js-denktank').stop().animate({
					// 		'right':'5vw'
					// 	},1000);

					// }

					_element.addClass("triggered");

				}

			}

		};
		
}).apply(specific_interactions_window); 