/*
Version 0.115

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	01-03-2018
	- [ADDED] Added document.ready to onready.
	26-02-2018
	- [ADDED] Open en Close methods are extended with new function to prevent body scrolling when menu is open
2017
	30-11-2017
	- [CHANGE] .js-menu-button is now changed to .js-mobile-menu-button
	15-10-2017
	- [METHOD] Add and remove .active class to the js-menu-button.
	30-08-2017
	- [BORN] This class is born. Hooray!

!Usage
	specific_mobilemenu.onready();

	
!Wishlist

	none
	
*/
  
var specific_logo = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	// none
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	this.onready = function() {

		//$('.js-exit').on('click',function() {
		//	history.back();
		//});
		
		/*
		Show logo first time
		*/
		if(
			url[1] == '' ||
			( 
				url[1] != 'werk' &&
				url[2] == ''
			) ||
			( 
				url[1] == 'werk' &&
				url[2] == ''
			) ||
			( 
				url[1] == ''
			)

		) {

			setTimeout(function() {
				
				if($('.js-exit').hasClass('show')) {
					$('.js-exit').addClass('gone').removeClass('show');
				}

				setTimeout(function() {
					$('.js-logo').addClass('show').removeClass('gone');
				},300);

			},500);	

		} else {

			setTimeout(function() {
				
				if($('.js-logo').hasClass('show')) {
					$('.js-logo').removeClass('show').addClass('gone');
				}

				setTimeout(function() {
					$('.js-exit').addClass('show').removeClass('gone');
				},200);

			},500);	

		}
				
	};
		
}).apply(specific_logo); 